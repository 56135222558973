import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import { getLessonList, getCourses, getLessons, getLanguagePack } from '../service/service.js'
import { Collapse } from 'react-collapse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ReactGA from 'react-ga';
import cookie from 'react-cookies';
import SelectLanguage from './selectLanguage.js';
import '../components/ModalSeduc.css';
import CampanhaSeduc from '../assets/images/campanha_seduc.png'; // Ajuste o caminho conforme a localização


class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      catchupId: null,
      lessonId: null,
      lessonsLoading: true,
      lessonsLoadingError: false,
      lessonsList: [],
      collapse: false,
      courses: [],
      coursesLoading: true,
      modalOpen: false,
      popupVisible: true,
      currentLanguage: this.getCurrentLanguage(),
      languagePackage: getLanguagePack()
    };
  }

  getCurrentLanguage(){
    let data = localStorage.getItem('languagePack');
    return data == null ? 'en' : JSON.parse(data).selectedLanguage;
  }

  _getMessage(message) {
    return this.state.languagePackage[message] ? this.state.languagePackage[message] : message;
  }

  _getLessons(){
    this.setState({
      lessonsLoading: true,
      lessonsLoadingError: false
    })
    getLessonList().then(res =>{
      console.log("getLessonList success:", res)
      this.setState({ 
        lessonsLoadingError: false,
        lessonsList: res
      })
    }).catch(err => {
      if(err.status === 404){
        this.setState({ lessonsLoadingError: false })
      }else{
        this.setState({ lessonsLoadingError: true })
      }
      console.log("getLessonList error: ", err)
    }).finally(() => {
      this.setState({ lessonsLoading: false })
    })
  }

  formatDate(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  }

  closePopup = () => {
    this.setState({ popupVisible: false });
  };

  componentDidMount() {
    let trackingId = 'UA-1839981-59';
    let userId = cookie.load('userId') || 'undefined';
    ReactGA.initialize(trackingId);

    ReactGA.set({
      user_id: userId
    });

    ReactGA.event({
      category: "Catchup",
      action: "Accessed Home Page",
    });

    // Liçoes antes do corona
    // this._getLessons()

    ReactGA.event({
      category: "Catchup",
      action: "Load Courses",
    });

    // Liçoes depois do corona

    getCourses({ page: 1, page_size: 50 }).then(res => {
      let data = res.data;
      for (let i = 0; i < data.length; i++) {
        data[i].collapsed = false;
        data[i].lessons = [];
        data[i].isLoading = false;
        data[i].currentPage = 1;
      }
      this.setState({
        courses: data
      });
    }).catch(err => {
      console.log('error courses: ', err);
    }).finally(() => {
      this.setState({ coursesLoading: false });
    });
  }

  closeModal(){
    this.setState({ modalOpen: false })
  }

  openModal(){
    this.setState({ modalOpen: true })
  }

  openCollapse(index, id){
    let courses = this.state.courses

    courses[index].collapsed = !courses[index].collapsed

    this.setState({ courses }, () => {

      if( courses[index].lessons.length === 0 ){ // carregar liçoes somente se não estiver carregado antes

          courses[index].isLoading = true

          this.setState({ courses })

          ReactGA.event({
            category: "Catchup",
            action: "Load Lessons",
            label: 'page1|size10'
          });

          getLessons(id, { page: 1, page_size: 10 }).then(lessonData => {
            courses[index].lessons = lessonData.data
            courses[index].isLoading = false
            courses[index].currentPage += 1
            courses[index].pageLimit = Math.ceil(lessonData.total / 10)

            this.setState({ courses })
        }).catch(err => {
            console.log('error lessons: ', err)

            courses[index].isLoading = false

            this.setState({ courses })
        })
      }

    })


  }

  loadMore(index, id){
    let courses = this.state.courses

    courses[index].isLoading = true
    this.setState({ courses })

    console.log('current page: ', courses[index].currentPage)

    ReactGA.event({
      category: "Catchup",
      action: "Load Lessons",
      label: `page${courses[index].currentPage}|size10`
    });

    getLessons(id, { page: courses[index].currentPage, page_size: 10 }).then(lessonData => {
      console.log('GET LESSONS RESPONSE: ', lessonData)
      courses[index].lessons = courses[index].lessons.concat(lessonData.data)
      courses[index].isLoading = false
      courses[index].currentPage += 1
      this.setState({ courses }, () => { console.log('updated: ', this.state.courses) })
    }).catch(err => {
      courses[index].isLoading = false
      this.setState({ courses })
      console.log('GetLessons Error: ', err)
    })
  }

  render() {
    const blockLessons = ['Clase Cero', 'Zero Class'];

    return (
      <div>
        {this.state.popupVisible && (
          <div className="popup-overlay">
            <div className="popup-container">
              <button className="popup-close-button" onClick={this.closePopup}>&times;</button>
              <p className="popup-text">Já garantiu sua oferta especial?</p>
              <a href="http://q-r.to/WZD_ON" target="_blank" rel="noopener noreferrer">
                <img src={CampanhaSeduc} alt="Popup" className="popup-image" />
              </a>
            </div>
          </div>
        )}

        {this.state.modalOpen && (
          <SelectLanguage closeModal={() => this.setState({ modalOpen: false })} />
        )}

        <div>
          <Header menuLanguage={true} />
        </div>
        <div className="container-fluid d-flex home-container justify-content-center background-image" >
          <div className="" />
          <div className="">
            <div className="col-md-12">
              <div className="welcome_title">
                <h4 title="Aluno" className="js_user_first_name">
                  {this._getMessage('Olá, Aluno')}
                </h4>
                <p>
                  {
                    this._getMessage('Estas são as aulas disponíveis para você fazer. Escolha uma para começar!')
                  }
                </p>
              </div>
            </div>
            <div className="card" style={{ marginBottom: 50 }}>
              {/* Liçoes depois do corona */}
              <div style={{ padding: 20 }}>

                {
                  this.state.coursesLoading &&
                  <div className="loading-lessons-container">
                    <div className="spinner-border home-loading" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div style={{ color: 'rgb(117,117,117)' }}>
                      {
                        this._getMessage('Carregando cursos...')
                      }
                    </div>
                  </div>
                }

                {
                  this.state.courses.length === 0 && !this.state.coursesLoading ?
                    <div className="home-no-lessons">
                        No lessons available.
                    </div>
                  :
                    this.state.courses.filter(item => item.book.language.code === this.state.currentLanguage).map((item, index) => {
                      return(
                      <div key={index} className="all-lessons-container">
                        <div className="all-lessons-title" onClick={() => this.openCollapse(index, item.book.id) }>
                          <FontAwesomeIcon style={{ color: 'lightslategray', fontSize: 18, marginRight: 5, width: 20 }} icon={ this.state.courses[index].collapsed ? faChevronDown : faChevronRight } />
                          { item.book.name }
                        </div>
                        <Collapse isOpened={this.state.courses[index].collapsed}>
                          {
                            this.state.courses[index].lessons.filter(a => (!a.is_prepost && !blockLessons.includes(a.name) )).map((lesson, lessonIndex) => {
                              return(
                                <div style={{ marginLeft: 10, padding: 3 }} key={lessonIndex}>
                                  <Link to={`/catchupId/1/lesson/${lesson.id}/0`}>{lesson.name}</Link>
                                </div>
                              )
                            })
                          }
                          {
                            this.state.courses[index].isLoading && 
                            <div style={{ display: 'flex', padding: 5, paddingLeft: 10, alignItems: 'center' }}>
                              <div className="spinner-border-lessons home-loading" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                              <div style={{ color: 'rgb(117,117,117)', marginLeft: 10 }}>
                                
                                {
                                  this._getMessage('Carregando lições...')
                                }
                              </div>
                            </div>
                          }
                          {
                            !this.state.courses[index].isLoading && (this.state.courses[index].currentPage <= this.state.courses[index].pageLimit) && 
                            <div style={{ color: 'rgb(117,117,117)', marginLeft: 13, marginTop: 5, cursor: 'pointer' }} onClick={() => this.loadMore(index, item.book.id)}>
                              {
                                this._getMessage('Carregar mais')
                              }
                            </div>
                          }
                        </Collapse>
                      </div>
                      )
                    })
                }
              </div>

              {/* Liçoes antes do Corona */}
              {/* <div className="card-body">
              {
                this.state.lessonsLoading == true ? // show loading animation
                <div className="loading-lessons-container">
                  <div className="spinner-border home-loading" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div style={{ color: 'rgb(117,117,117)' }}>
                    Loading lessons...
                  </div>
                </div>
                :
                  this.state.lessonsList.length == 0 ? // if user has no content to be loaded
                  <div className="home-no-lessons">
                    <div>
                      {
                        this.state.lessonsLoadingError
                          ? "Error loading lessons, try again later."
                          : "No lessons available."
                      }
                    </div>
                    <div onClick={() => { this._getLessons() }} className="btn btn-primary btn-lg btn-block-home btn-login login-button">
                      Reload
                    </div>
                  </div>
                  :
                  <table className="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Livro</th>
                        <th scope="col">Aula</th>
                        <th scope="col">Data</th>
                        <th scope="col">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        this.state.lessonsList.map((item, index) =>{
                          return(
                            <tr key={index}>
                              <th scope="row">{ index }</th>
                              <td>{ item.book.name }</td>
                              <td>{ item.lesson.name }</td>
                              <td>{ this.formatDate(item.data_hora) }</td>
                              <td>
                                <Link to={`/catchupId/${item.catchup_id}/lesson/${item.lesson.id}/0`}>Começar</Link>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
              }
              </div> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;